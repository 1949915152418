import DatePicker from 'components/common/DatePicker';
import PropTypes from 'prop-types';

export const ControlDate = ({ value, onChange }) => {
  return (
    <div
      className="position-absolute"
      style={{
        bottom: 1,
        zIndex: 999,
        borderRadius: 8,
        backgroundColor: 'white',
        left: '50%',
        transform: 'translate(-50%, 0%)'
      }}>
      <DatePicker wrapperClassName="m-0" value={value} onChange={onChange} disabled={true} />
    </div>
  );
};

ControlDate.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};
