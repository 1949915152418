export const DEFAULT_DATE = '2024-09-09';
export const DEFAULT_DISPLAY_DEFECT_DETAIL = {
  open: false,
  defect: null
};
export const RATIO = 0.3;
export const SEVERITY_COLOR = {
  Safe: '#efd058',
  'Require Repair': '#f5a768',
  Unsafe: '#e25757'
};
