import PropTypes from 'prop-types';

export const ControlFunction = ({ isCompare, turnOnCompare }) => {
  return (
    <div
      className="position-absolute"
      style={{
        right: 8,
        bottom: 8,
        padding: 8,
        zIndex: 999,
        borderRadius: 8,
        backgroundColor: 'white'
      }}>
      {!isCompare && (
        <div
          title="Split view"
          data-placement="left"
          data-toggle="tooltip"
          onClick={turnOnCompare}
          className="cursor-pointer">
          <i className="fa fa-columns" aria-hidden="true" style={{ fontSize: 24 }}></i>
        </div>
      )}
    </div>
  );
};

ControlFunction.propTypes = {
  isCompare: PropTypes.bool.isRequired,
  turnOnCompare: PropTypes.func.isRequired
};
