/**
 * @author Tran Trung
 * @copyright 06/06/2019 Kyanon Digital
 */
import { SINGLE_API, UPDATE_STORE } from './type';

export const getUserProfile = (payload, next, nextErr) => {
  const { organization_id } = payload;
  return {
    type: SINGLE_API,
    payload: {
      uri: `organization/${organization_id}/profile`,
      beforeCallType: 'GET_USER_INFO_REQUEST',
      successType: 'GET_USER_INFO_SUCCESS',
      afterSuccess: next,
      afterError: nextErr
    }
  };
};
export const updateProfile = (payload, next, nextErr) => {
  const { first_name, last_name, organization_id } = payload;
  return {
    type: SINGLE_API,

    payload: {
      uri: `organization/${organization_id}/update`,
      beforeCallType: 'UPDATE_USER_INFO_REQUEST',
      successType: 'UPDATE_USER_INFO_SUCCESS',
      afterSuccess: next,
      afterError: nextErr,
      opt: {
        method: 'PUT'
      },
      params: {
        first_name,
        last_name
      }
    }
  };
};

export const enable_Auth = (payload, next, nextErr) => {
  const { email } = payload;
  return {
    type: SINGLE_API,

    payload: {
      uri: `cmfa/email/request/`,
      beforeCallType: 'ENABLE_AUTH_REQUEST',
      successType: 'ENABLE_AUTH_SUCCESS',
      afterSuccess: next,
      afterError: nextErr,
      opt: {
        method: 'PUT'
      },
      params: {
        email
      }
    }
  };
};

export const verify_Auth = (payload, next, nextErr) => {
  const { mfa_code } = payload;
  return {
    type: SINGLE_API,

    payload: {
      uri: `cmfa/email/verify/`,
      beforeCallType: 'VERIFY_AUTH_REQUEST',
      successType: 'VERIFY_AUTH_SUCCESS',
      afterSuccess: next,
      afterError: nextErr,
      opt: {
        method: 'PUT'
      },
      params: {
        mfa_code
      }
    }
  };
};

export const disable_Auth = (payload, next, nextErr) => {
  return {
    type: SINGLE_API,
    payload: {
      uri: `cmfa/`,
      beforeCallType: 'ENABLE_AUTH_REQUEST',
      successType: 'ENABLE_AUTH_SUCCESS',
      afterSuccess: next,
      afterError: nextErr,
      opt: {
        method: 'DELETE'
      }
    }
  };
};

export const updateProfileStore = (payload, next) => {
  return {
    type: UPDATE_STORE,
    payload: {
      type: 'UPDATE_PROFILE_STORE',
      user: payload,
      afterSuccess: next
    }
  };
};

export const changePassword = (payload, next, nextErr) => {
  const { old_password, new_password1, new_password2 } = payload;
  return {
    type: SINGLE_API,
    payload: {
      uri: `authentication/password/change/`,
      beforeCallType: 'CHANGE_PASSWORD_REQUEST',
      successType: 'CHANGE_PASSWORD_SUCCESS',
      afterSuccess: next,
      afterError: nextErr,
      opt: {
        method: 'POST'
      },
      params: {
        old_password,
        new_password1,
        new_password2
      }
    }
  };
};

export const forgeChangePassword = (payload, next, nextErr) => {
  const { new_password1, new_password2 } = payload;
  return {
    type: SINGLE_API,
    payload: {
      uri: `cmfa/force-change-password`,
      beforeCallType: 'FORGE_CHANGE_PASSWORD_REQUEST',
      successType: 'FORGE_CHANGE_PASSWORD_SUCCESS',
      afterSuccess: next,
      afterError: nextErr,
      opt: {
        method: 'POST'
      },
      params: {
        new_password1,
        new_password2
      }
    }
  };
};

export const resetPassword = (payload, next, nextErr) => {
  const { email } = payload;
  return {
    type: SINGLE_API,
    payload: {
      uri: `authentication/password/reset/`,
      beforeCallType: 'RESET_PASSWORD_REQUEST',
      successType: 'RESET_PASSWORD_SUCCESS',
      afterSuccess: next,
      afterError: nextErr,
      opt: {
        method: 'POST'
      },
      params: {
        email
      }
    }
  };
};

export const checkTokenResetPassword = (payload, next, nextErr) => {
  const { uid, token } = payload;
  return {
    type: SINGLE_API,
    payload: {
      uri: `authentication/password/reset/check_token`,
      beforeCallType: 'CHECK_TOKEN_REQUEST',
      successType: 'CHECK_TOKEN_SUCCESS',
      afterSuccess: next,
      afterError: nextErr,
      opt: {
        method: 'POST'
      },
      params: {
        uid,
        token
      }
    }
  };
};

export const confirmResetPassword = (payload, next, nextErr) => {
  const { uid, token, pass1, pass2 } = payload;
  return {
    type: SINGLE_API,
    payload: {
      uri: `authentication/password/reset/confirm/`,
      beforeCallType: 'CONFIRM_PASSWORD_REQUEST',
      successType: 'CONFIRM_PASSWORD_SUCCESS',
      afterSuccess: next,
      afterError: nextErr,
      opt: {
        method: 'POST'
      },
      params: {
        uid,
        token,
        new_password1: pass1,
        new_password2: pass2
      }
    }
  };
};

export const register = (payload, next, nextErr) => {
  const {
    first_name,
    last_name,
    email,
    password1,
    password2,
    organization_name,
    contact_number,
    address,
    domain_name
  } = payload;
  return {
    type: SINGLE_API,
    payload: {
      uri: `authentication/registration/`,
      beforeCallType: 'REGISTER_REQUEST',
      successType: 'REGISTER_SUCCESS',
      afterSuccess: next,
      afterError: nextErr,
      opt: {
        method: 'POST'
      },
      params: {
        first_name,
        last_name,
        email,
        password1,
        password2,
        organization_name,
        contact_number,
        address,
        domain_name
      }
    }
  };
};

export const getAreasNLocation = (payload, next, nextError) => {
  return {
    type: SINGLE_API,
    payload: {
      uri: 'areas-locations',
      beforeCallType: 'GET_AREAS_AND_LOCATIONS_REQUEST',
      successType: 'GET_AREAS_AND_LOCATIONS_SUCCESS',
      afterSuccess: next,
      afterError: nextError
    }
  };
};

export const checkExpiredPassword = (payload, next, nextError) => {
  return {
    type: SINGLE_API,
    payload: {
      uri: 'cmfa/password-expiry',
      beforeCallType: 'CHECK_PASSWORD_EXPIRED_REQUEST',
      successType: 'CHECK_PASSWORD_EXPIRED_SUCCESS',
      afterSuccess: next,
      afterError: nextError
    }
  };
};
