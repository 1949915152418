import { Viewer, utils } from '@photo-sphere-viewer/core';
import { MarkersPlugin } from '@photo-sphere-viewer/markers-plugin';
import { PlanPlugin } from '@photo-sphere-viewer/plan-plugin';
import { VirtualTourPlugin } from '@photo-sphere-viewer/virtual-tour-plugin';
import {
  DEFAULT_DATE,
  DEFAULT_DISPLAY_DEFECT_DETAIL,
  RATIO,
  SEVERITY_COLOR
} from 'constants/immersive-view';
import { useBoolean } from 'hooks/useBoolean';
import L from 'leaflet';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ControlCloseSite, ControlDate, ControlFunction, ControlLockView } from '../ControlPanel';
import CreateNewDefectModal from '../CreateNewDefectModal';
import DefectDetailModal from '../DefectDetailModal';
import { convertPointBetweenImages, quatToEuler } from 'utils/immersive-view';

const getDefectIcon = (severity) => {
  switch (severity) {
    case 'Safe':
      return '/images/icons/safe.svg';
    case 'Unsafe':
      return '/images/icons/unsafe.svg';
    case 'Require Repair':
      return '/images/icons/require_repair.svg';
    default:
      return '';
  }
};

const getPlugins = (planImage) => {
  return [
    [
      PlanPlugin,
      {
        position: 'top right',
        buttons: {
          reset: false,
          close: false
        },
        size: {
          height: planImage.miniHeight + 'px',
          width: planImage.miniWidth + 'px'
        },
        defaultZoom: 0,
        configureLeaflet(map) {
          const imageBounds = [
            [0, 0],
            [planImage.miniHeight, planImage.miniWidth]
          ];

          map.options.crs = L.CRS.Simple;
          L.imageOverlay(planImage.imageSource, imageBounds).addTo(map);

          map.setMaxZoom(2);
          map.setMinZoom(0);
          map.fitBounds(imageBounds);
          map.setMaxBounds(imageBounds);
        }
      }
    ],
    [MarkersPlugin],
    [
      VirtualTourPlugin,
      {
        renderMode: '3d',
        transitionOptions: {
          showLoader: false
        },
        preload: true
      }
    ]
  ];
};

const MAP_SIZE = {
  maximized: 'maximized',
  normal: 'normal'
};

const SyncView = ({ data, onLoading, offLoading }) => {
  const [locked, setLock] = useState(false);
  const [isCompare, onCompare, offCompare] = useBoolean(false);
  const [displayDefectDetail, setDisplayDefectDetail] = useState(DEFAULT_DISPLAY_DEFECT_DETAIL);

  const [open, setOpen] = useState(false);
  const viewer1Ref = useRef(null);
  const viewer2Ref = useRef(null);
  const container1Ref = useRef(null);
  const container2Ref = useRef(null);

  const [newMarkerId, setNewMarkerId] = useState('');
  const [currentIndexNodeViewer1, setCurrentIndexNodeViewer1] = useState(0);
  const [currentIndexNodeViewer2, setCurrentIndexNodeViewer2] = useState(0);
  const [currentDateViewer1, setCurrentDateViewer1] = useState(DEFAULT_DATE);
  const [currentDateViewer2, setCurrentDateViewer2] = useState(DEFAULT_DATE);

  const toggleLock = useCallback((nextValue) => {
    if (typeof nextValue !== 'undefined') return setLock(nextValue);
    setLock((prev) => !prev);
  }, []);

  const { images: viewer1Images, twoPts_angle: twoPointAngle1 } = data[currentDateViewer1] || {};
  const planImageViewer1 = {
    imageSource: data[currentDateViewer1]?.floorPlanImage || '',
    width: data[currentDateViewer1]?.floorPlanImageWidth || '',
    height: data[currentDateViewer1]?.floorPlanImageHeight || '',
    miniWidth: data[currentDateViewer1]?.floorPlanImageWidth * RATIO || '',
    miniHeight: data[currentDateViewer1]?.floorPlanImageHeight * RATIO || ''
  };

  const { images: viewer2Images, twoPts_angle: twoPointAngle2 } = data[currentDateViewer2] || {};
  const planImageViewer2 = {
    imageSource: data[currentDateViewer2]?.floorPlanImage || '',
    width: data[currentDateViewer2]?.floorPlanImageWidth || '',
    height: data[currentDateViewer2]?.floorPlanImageHeight || '',
    miniWidth: data[currentDateViewer2]?.floorPlanImageWidth * RATIO || '',
    miniHeight: data[currentDateViewer2]?.floorPlanImageHeight * RATIO || ''
  };

  const syncViewers = (sourceViewer, targetViewer, locked) => {
    if (!locked || !sourceViewer || !targetViewer) return;
    try {
      targetViewer.rotate({
        pitch: sourceViewer.getPosition().pitch,
        yaw: sourceViewer.getPosition().yaw
      });
      targetViewer.zoom(sourceViewer.getZoomLevel());
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleOnOk = async (values) => {
    try {
      const virtualTourPlugin = viewer1Ref.current.getPlugin(VirtualTourPlugin);
      const currentNodeId = `node-${currentIndexNodeViewer1}`;
      const { hotspots } = await detectInformation(viewer1Images, planImageViewer1.miniHeight);
      const currentNode = virtualTourPlugin.getCurrentNode();
      const randomPositionForPlan = {
        x: currentNode.positionOnMap.x + Math.floor(Math.random() * 60 - 30),
        y: currentNode.positionOnMap.y + Math.floor(Math.random() * 60 - 30)
      };
      const image = viewer1Images[currentIndexNodeViewer1];
      try {
        const planPlugin = viewer1Ref.current?.getPlugin(PlanPlugin);
        const markersPlugin = viewer1Ref.current.getPlugin(MarkersPlugin); // TODO: replace color new marker
        planPlugin.setHotspots(
          hotspots.concat({
            size: 22,
            color: SEVERITY_COLOR[values.severity.label],
            id: `${currentNodeId}-new-hotspot-${Date.now()}`,
            nodeId: currentNodeId,
            coordinates: [randomPositionForPlan.x, randomPositionForPlan.y],
            posistionOnMap: { x: image.x * RATIO, y: planImageViewer1.height - image.y * RATIO }
          })
        );
      } catch (error) {
        console.log('error', error);
      }

      // try {
      //   const planPlugin = viewer2Ref.current?.getPlugin(PlanPlugin);
      //   const { hotspots } = detectInformation(viewer2Images, planImageViewer2.miniHeight);

      //   planPlugin.setHotspots(
      //     hotspots.concat({
      //       color: 'blue',
      //       id: `${currentNodeId}-new-hotspot-${Date.now()}`,
      //       nodeId: currentNodeId,
      //       coordinates: [randomPositionForPlan.x, randomPositionForPlan.y],
      //       posistionOnMap: { x: image.x / 2, y: planImageViewer2.height - image.y / 2 }
      //     })
      //   );
      // } catch (error) {
      //   console.log('error', error);
      // }

      setOpen(false);
      setNewMarkerId('');
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleOnCancel = () => {
    try {
      try {
        if (viewer1Ref.current) {
          const viewer = viewer1Ref.current;
          const markersPlugin = viewer.getPlugin(MarkersPlugin);
          markersPlugin.removeMarker(newMarkerId);
        }
      } catch (error) {
        console.log(error);
      }

      try {
        if (viewer2Ref.current) {
          const viewer = viewer2Ref.current;
          const markersPlugin = viewer.getPlugin(MarkersPlugin);
          markersPlugin.removeMarker(newMarkerId);
        }
      } catch (error) {
        console.log(error);
      }
      setOpen(false);
      setNewMarkerId('');
    } catch (error) {
      console.log(error);
    }
  };

  const detectLinksNodeByImages = useCallback((images, nodeIndex) => {
    const counterNode = images.length;
    if (nodeIndex === 0) {
      const nextIndex = nodeIndex + 1;
      const nextNode = {
        nodeId: `node-${nextIndex}`,
        position: convertPointBetweenImages(
          images[nextIndex],
          images[nodeIndex],
          images[nodeIndex].exif_image_width,
          images[nodeIndex].exif_image_height
        )
      };
      return [nextNode];
    }
    if (nodeIndex >= counterNode - 1) {
      const nextIndex = nodeIndex - 1;
      const nextNode = {
        nodeId: `node-${nextIndex}`,
        position: convertPointBetweenImages(
          images[nextIndex],
          images[nodeIndex],
          images[nodeIndex].exif_image_width,
          images[nodeIndex].exif_image_height
        )
      };
      return [nextNode];
    }
    const nextIndex = nodeIndex + 1;
    const prevIndex = nodeIndex - 1;
    const nextNode = {
      nodeId: `node-${nextIndex}`,
      position: convertPointBetweenImages(
        images[nextIndex],
        images[nodeIndex],
        images[nodeIndex].exif_image_width,
        images[nodeIndex].exif_image_height
      )
    };
    const prevNode = {
      nodeId: `node-${prevIndex}`,
      position: convertPointBetweenImages(
        images[prevIndex],
        images[nodeIndex],
        images[nodeIndex].exif_image_width,
        images[nodeIndex].exif_image_height
      )
    };
    return [nextNode];
  }, []);

  const detectInformation = useCallback(async (images, height) => {
    const nodes = [];
    const hotspots = [];
    const nodeMap = new Map();
    const hotspotsMap = new Map();

    for (let imageIndex = 0; imageIndex < images.length; imageIndex++) {
      const image = images[imageIndex];

      const currentNodeId = `node-${imageIndex}`;
      // HOTSPOTS
      hotspotsMap.set(currentNodeId, {
        color: '#0587ff47',
        id: currentNodeId,
        nodeId: currentNodeId,
        coordinates: [image.x * RATIO, height - image.y * RATIO],
        posistionOnMap: { x: image.x * RATIO, y: height - image.y * RATIO }
      });
      image.defect.forEach((defect, index) => {
        const currentHotspotId = `${currentNodeId}-hotspot-${index}`;
        hotspotsMap.set(currentHotspotId, {
          id: currentHotspotId,
          nodeId: currentNodeId,
          coordinates: [defect.x_loc * RATIO, height - defect.y_loc * RATIO],
          color: SEVERITY_COLOR[defect.severity],
          size: 22,
          posistionOnMap: { x: image.x * RATIO, y: height - image.y * RATIO },
          data: defect
        });
      });
      // NODES
      nodeMap.set(currentNodeId, {
        id: currentNodeId,
        index: imageIndex,
        panorama: image.url,
        thumbnail: image.url,
        name: currentNodeId,
        links: detectLinksNodeByImages(images, imageIndex),
        markers: [
          ...image.defect.map((defect, index) => {
            const currentMarkerId = `${currentNodeId}-defect-marker-${index}`;
            return {
              data: defect,
              id: currentMarkerId,
              position: { yaw: defect.yaw + 'deg', pitch: defect.pitch + 'deg' },
              image: getDefectIcon(defect.severity),
              size: { width: 48, height: 48 },
              tooltip: {
                content: `<div class="pb-2 pt-2">
                              <div>Defect ID: ${defect.id}</div>
                              <div class="mt-2">
                                  <img src=${defect.imageUrl} alt="Defect Image" class="w-100">
                              </div>
                          </div>`,
                className: 'custom-tooltip',
                position: 'right'
              }
            };
          })
        ],
        positionOnMap: { x: image.x * RATIO, y: height - image.y * RATIO }
      });
    }

    nodeMap.forEach((value) => {
      nodes.push(value);
    });
    hotspotsMap.forEach((value) => {
      hotspots.push(value);
    });

    return {
      nodes,
      hotspots,
      nodeMap,
      hotspotsMap
    };
  }, []);

  const detectBearing = useCallback(({ images, currentIndexNode, viewer, twoPtsAngle }) => {
    const initYaw = quatToEuler(images[currentIndexNode].quats).yaw;
    const compassYaw = viewer.getPosition().yaw;

    const bearing = initYaw - twoPtsAngle + compassYaw;
    return utils.parseAngle(bearing + 'deg');
  }, []);

  const firstLoadViewer = useCallback(async (viewer, images, planImageView, currentIndexNode) => {
    const { nodeMap, hotspots, nodes } = await detectInformation(images, planImageView.miniHeight);
    const markersPlugin = viewer.getPlugin(MarkersPlugin);
    const planPlugin = viewer.getPlugin(PlanPlugin);
    const virtualTourPlugin = viewer.getPlugin(VirtualTourPlugin);

    const currentNodeId = `node-${currentIndexNode}`;
    const currentNode = nodeMap.get(currentNodeId);
    const bearing = detectBearing({
      viewer,
      images,
      currentIndexNode,
      twoPtsAngle: twoPointAngle1
    });

    viewer.zoom(0);
    planPlugin.setOptions({
      bearing: bearing
    });
    virtualTourPlugin.setNodes(nodes, [currentNodeId]);
    currentNode.markers.forEach((marker) => {
      markersPlugin.addMarker(marker);
    });
    planPlugin?.setHotspots(hotspots); // TODO: improve performance plan
    planPlugin?.setCoordinates([currentNode.positionOnMap.x, currentNode.positionOnMap.y]);
  }, []);

  const initialEvents = async (viewer, images, planImageView, positionViewer) => {
    const { nodeMap, hotspotsMap } = await detectInformation(images, planImageView.miniHeight);
    const markersPlugin = viewer.getPlugin(MarkersPlugin);
    const planPlugin = viewer.getPlugin(PlanPlugin);
    const virtualTourPlugin = viewer.getPlugin(VirtualTourPlugin);
    const map = planPlugin.getLeaflet();

    // events
    viewer.addEventListener('dblclick', (e) => {
      const { data } = e;
      if (!isCompare) {
        const newDefectId = `new-defect-${Date.now()}`;
        markersPlugin.addMarker({
          id: newDefectId,
          position: { yaw: data.yaw, pitch: data.pitch },
          image: '/images/icons/new-defect.svg',
          size: { width: 38, height: 38 }
        });

        try {
          if (viewer2Ref.current) {
            const markersPlugin2 = viewer2Ref.current?.getPlugin(MarkersPlugin);
            markersPlugin2.addMarker({
              id: newDefectId,
              position: { yaw: data.yaw, pitch: data.pitch },
              image: '/images/icons/new-defect.svg',
              size: { width: 38, height: 38 }
            });
          }
        } catch (error) {
          console.log(error);
        }

        setOpen(true);
        setNewMarkerId(newDefectId);
      }
    });

    planPlugin?.addEventListener('select-hotspot', ({ hotspotId }) => {
      try {
        const selectedHotspot = hotspotsMap.get(hotspotId);
        virtualTourPlugin.setCurrentNode(selectedHotspot.nodeId);
        const currentNode = nodeMap.get(selectedHotspot.nodeId);
        let bearing;

        if (positionViewer === 0) {
          bearing = detectBearing({
            viewer,
            images,
            twoPtsAngle: twoPointAngle1,
            currentIndexNode: currentNode.index
          });
        } else {
          bearing = detectBearing({
            viewer,
            images,
            twoPtsAngle: twoPointAngle2,
            currentIndexNode: currentNode.index
          });
        }
        planPlugin.setOptions({
          bearing: bearing
        });
        if (!selectedHotspot.data) {
          console.log('Select image hotspot');
        } else {
          viewer.rotate({
            pitch: selectedHotspot.data.pitch + 'deg',
            yaw: selectedHotspot.data.yaw + 'deg'
          });
        }
      } catch (error) {
        console.log('error', error);
      }
    });

    planPlugin?.addEventListener('view-changed', ({ view }) => {
      switch (view) {
        case MAP_SIZE.maximized:
          map.setZoom(1);
          break;
        case MAP_SIZE.normal:
        default:
          map.setZoom(0);
      }
    });

    markersPlugin.addEventListener('select-marker', ({ marker }) => {
      const defect = marker.config.data || {};

      setDisplayDefectDetail({
        open: true,
        defect: defect
      });
    });

    virtualTourPlugin.addEventListener('node-changed', ({ node, data }) => {
      if (!data.fromNode) return;
      const currentNode = nodeMap.get(node.id);

      markersPlugin.clearMarkers();
      currentNode.markers.forEach((marker) => {
        markersPlugin.addMarker(marker);
      });
      planPlugin.setCoordinates([currentNode.positionOnMap.x, currentNode.positionOnMap.y]);
      let bearing;
      if (positionViewer === 0) {
        setCurrentIndexNodeViewer1(currentNode.index);
        bearing = detectBearing({
          images,
          currentIndexNode: currentNode.index,
          viewer,
          twoPtsAngle: twoPointAngle1
        });
        planPlugin.setOptions({
          bearing: bearing
        });
      } else {
        setCurrentIndexNodeViewer2(currentNode.index);
        bearing = detectBearing({
          images,
          currentIndexNode: currentNode.index,
          viewer,
          twoPtsAngle: twoPointAngle2
        });
      }
      planPlugin.setOptions({
        bearing: bearing
      });
      console.log('set false');
      toggleLock(false);
    });
  };

  // INITIAL VIEWER
  useEffect(() => {
    if (container1Ref.current) {
      try {
        const viewer = new Viewer({
          container: container1Ref.current,
          plugins: getPlugins(planImageViewer1),
          zoomSpeed: 50,
          navbar: ['zoom']
        });

        firstLoadViewer(viewer, viewer1Images, planImageViewer1, currentIndexNodeViewer1);
        initialEvents(viewer, viewer1Images, planImageViewer1, 0);

        viewer1Ref.current = viewer;
      } catch (error) {
        console.log('error', error);
      }
    }

    return () => {
      if (viewer1Ref.current) {
        console.log('Viewer 1 is destroyed');
        viewer1Ref.current.destroy();
      }
    };
  }, [viewer1Images]);

  useEffect(() => {
    if (!isCompare) return;
    if (container2Ref.current) {
      try {
        const indexNodeViewer = isCompare ? currentIndexNodeViewer1 : currentIndexNodeViewer2;
        const viewer = new Viewer({
          container: container2Ref.current,
          plugins: getPlugins(planImageViewer2),
          zoomSpeed: 50,
          navbar: ['zoom']
        });

        firstLoadViewer(viewer, viewer2Images, planImageViewer2, indexNodeViewer);
        initialEvents(viewer, viewer2Images, planImageViewer2, 1);
        viewer2Ref.current = viewer;
      } catch (error) {
        console.log('error', error);
      }
    }

    return () => {
      if (viewer2Ref.current?.destroy) {
        console.log('Viewer 2 is destroyed');
        viewer2Ref.current.destroy();
      }
    };
  }, [viewer2Images, isCompare]);

  const handleSyncIndex = async () => {
    if (currentIndexNodeViewer1 !== currentIndexNodeViewer2 && viewer2Ref.current && locked) {
      const virtualTourPlugin = viewer2Ref.current.getPlugin(VirtualTourPlugin);
      virtualTourPlugin.setCurrentNode(`node-${currentIndexNodeViewer1}`);
      setTimeout(() => {
        console.log('set true');
        toggleLock(true);
      }, 1000);
    }
  };

  // HANDLE SYNC VIEW EVENT
  useEffect(() => {
    handleSyncIndex();
    const triggerSyncViewer1 = () => {
      syncViewers(viewer1Ref.current, viewer2Ref.current, locked);
    };
    const triggerSyncViewer2 = () => {
      syncViewers(viewer2Ref.current, viewer1Ref.current, locked);
    };

    if (viewer1Ref.current) {
      viewer1Ref.current.addEventListener('position-updated', triggerSyncViewer1);
      viewer1Ref.current.addEventListener('zoom-updated', triggerSyncViewer1);
    }
    if (viewer2Ref.current) {
      viewer2Ref.current.addEventListener('position-updated', triggerSyncViewer2);
      viewer2Ref.current.addEventListener('zoom-updated', triggerSyncViewer2);
    }

    return () => {
      if (viewer1Ref.current) {
        viewer1Ref.current.removeEventListener('position-updated', triggerSyncViewer1);
        viewer1Ref.current.removeEventListener('zoom-updated', triggerSyncViewer1);
      }

      if (viewer2Ref.current) {
        viewer2Ref.current.removeEventListener('position-updated', triggerSyncViewer2);
        viewer2Ref.current.removeEventListener('zoom-updated', triggerSyncViewer2);
      }
    };
  }, [locked]);

  return (
    <div>
      <div className="d-flex mt-3">
        <DefectDetailModal
          isOpen={displayDefectDetail.open}
          defect={displayDefectDetail.defect}
          onClose={() => setDisplayDefectDetail(DEFAULT_DISPLAY_DEFECT_DETAIL)}
        />

        <div
          ref={container1Ref}
          style={{
            position: 'relative',
            width: isCompare ? '50%' : '100%',
            height: '80vh',
            objectFit: 'contain'
          }}>
          {data[currentDateViewer1] ? (
            <>
              <ControlFunction isCompare={isCompare} turnOnCompare={onCompare} />
              <ControlDate
                value={currentDateViewer1}
                onChange={(value) => setCurrentDateViewer1(value)}
              />
            </>
          ) : (
            <div>Not found data</div>
          )}
        </div>

        <ControlLockView isCompare={isCompare} isLocked={locked} toggleLock={toggleLock} />

        <div
          ref={container2Ref}
          style={{
            width: isCompare ? '50%' : '0%',
            height: '80vh',
            objectFit: 'contain',
            position: 'relative'
          }}>
          {data[currentDateViewer2] ? (
            <>
              <ControlCloseSite
                isCompare={isCompare}
                turnOnCompare={onCompare}
                turnOffCompare={offCompare}
              />
              {isCompare && (
                <ControlDate
                  value={currentDateViewer2}
                  onChange={(value) => setCurrentDateViewer2(value)}
                />
              )}
            </>
          ) : (
            <div>Not found data</div>
          )}
        </div>
      </div>
      <CreateNewDefectModal open={open} onOk={handleOnOk} onCancel={handleOnCancel} />
    </div>
  );
};

export default SyncView;
