export const INITIAL_STATE = {
  requestIds: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'APPEND_REQUEST_ID':
      return { ...state, requestIds: [...state.requestIds, action.requestId] };
    case 'REMOVE_REQUEST_ID':
      return { ...state, requestIds: state.requestIds.filter((x) => x !== action.requestId) };
    default:
      return state;
  }
};
