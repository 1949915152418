import { Modal, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';

const LoadingModal = ({ loading }) => {
  return (
    <Modal show={loading} className="loading-modal" centered dialogClassName="bg-transparent">
      <Modal.Body className="text-center bg-transparent" style={{ height: 'unset' }}>
        <div className="d-flex align-items-center w-100 justify-content-center">
          <Spinner animation="border" role="status" className="text-white"></Spinner>
        </div>
      </Modal.Body>
    </Modal>
  );
};

LoadingModal.propTypes = {
  loading: PropTypes.bool.isRequired
};
export default LoadingModal;
