import { take, call, cancel, fork, put } from 'redux-saga/effects';
import AuthStorage from 'utils/AuthStorage';
import fetchApi, { generateRequestId } from 'utils/FetchApi';
import { appendRequestId, removeRequestId } from 'redux/action/loadingModal';
import { stores } from '../../index';

function* authorize(payload, next, nextErr) {
  const requestId = generateRequestId();
  stores.dispatch(appendRequestId(requestId));
  const response = yield call(fetchApi, {
    uri: 'cmfa/auth/login/',
    params: payload,
    opt: { method: 'POST', mode: 'cors' },
    loading: false
  });

  if (response && response.token) {
    const isAuthen = payload.mfa_code ? true : false;
    const data = {
      token: response.token,
      organizationId: response.organization_id,
      status: response.organization_status,
      role: response.user_role,
      plan: response.organization_plan,
      username: payload.username,
      isAuthen: isAuthen,
      isSuperAdmin: response.is_superadmin,
      isAdmin: response.is_admin,
      isPayment: response.is_payment,
      assignedBuildings: response.assigned_buildings,
      firstLogin: response.first_login,
      passwordExpired: response.password_expired
    };
    AuthStorage.value = data;
    yield put({
      type: 'LOGIN_SUCCESS',
      payload: data
    });
    if (typeof next === 'function') {
      next(data);
    }
    stores.dispatch(removeRequestId(requestId));
  } else {
    yield put({
      type: 'LOGIN_FAILED',
      payload: response
    });
    if (typeof nextErr === 'function') {
      nextErr();
    }
    stores.dispatch(removeRequestId(requestId));
  }
}

function* loginFlow() {
  const INFINITE = true;
  while (INFINITE) {
    const { payload, next, nextErr } = yield take('LOGIN_REQUEST');
    const authorizeTask = yield fork(authorize, payload, next, nextErr);
    const action = yield take(['LOGOUT_REQUEST', 'LOGIN_FAILED', 'REQUEST_ERROR']);

    if (action.type === 'LOGOUT_REQUEST') {
      yield cancel(authorizeTask);
    }
  }
}

function* logoutFlow() {
  const INFINITE = true;
  while (INFINITE) {
    const { next } = yield take('LOGOUT_REQUEST');
    yield call(AuthStorage.destroy);
    const data = {
      token: '',
      organizationId: '',
      status: '',
      role: '',
      plan: '',
      username: '',
      isAuthen: false,
      isSuperAdmin: false
    };
    window.localStorage.clear();
    AuthStorage.value = data;
    yield put({ type: 'LOGIN_SUCCESS', payload: data });
    if (typeof next === 'function') {
      next();
    }
  }
}

export default function* authFlow() {
  yield fork(loginFlow);
  yield fork(logoutFlow);
}
