import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import BaseModal from 'components/Modal/BaseModal';
import PropTypes from 'prop-types';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import Select from 'react-select';
import * as yup from 'yup';
import { COMPONENTS, FORM_DEFAULT_VALUES } from './constant';
import { useEffect } from 'react';
import ResetValueManager from './ResetValueManager';

const getSelectStyle = (error) => {
  return {
    control: (baseStyle) => ({
      ...baseStyle,
      ...(error && { border: '2px solid #d9534f' })
    })
  };
};

const validatorCreateNewDefect = yup.object().shape({
  severity: yup.object().nullable().required(),
  component: yup.object().nullable(),
  subComponent: yup.object().nullable(),
  material: yup.object().nullable(),
  description: yup.object().nullable(),
  type: yup.object().nullable(),
  intensity: yup.object().nullable(),
  extent: yup.object().nullable(),
  comment: yup.string(),
  resolution: yup.string()
});

const CreateNewDefectModal = ({ open, onCancel, onOk }) => {
  const methods = useForm({
    defaultValues: FORM_DEFAULT_VALUES,
    resolver: yupResolver(validatorCreateNewDefect)
  });

  const {
    reset,
    watch,
    control,
    register,
    handleSubmit,
    formState: { errors }
  } = methods;

  const component = watch('component');
  const subComponent = watch('subComponent');
  const material = watch('material');
  const description = watch('description');
  const type = watch('type');
  const intensity = watch('intensity');

  const subComponentOptions = component?.subComponent || [];
  const materialOptions = subComponent?.material || [];
  const descriptionOptions = material?.description || [];
  const typeOptions = description?.type || [];
  const intensityOptions = type?.intensity || [];
  const extentOptions = intensity?.extent || [];

  useEffect(() => {
    if (open) reset(FORM_DEFAULT_VALUES);
  }, [open]);

  return (
    <BaseModal
      size="modal-xl"
      isShow={open}
      header={
        <div className="modal-header">
          <h5 className="modal-title">Create new defect</h5>
          <button type="button" className="close" aria-label="Close" onClick={onCancel}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      }
      labels={{
        btnClose: 'Close'
      }}
      bodyStyle={{
        height: 'unset'
      }}
      closePopup={() => onCancel()}>
      <div>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onOk)}>
            <ResetValueManager /> {/* reset field value here */}
            <div className="row">
              <Controller
                name="severity"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <div className="col-6 form-group">
                    <label for="severity">Severity</label>
                    <Select
                      {...field}
                      openMenuOnFocus
                      inputId="severity"
                      options={[
                        { label: 'Safe' },
                        { label: 'Unsafe' },
                        { label: 'Require Repair' }
                      ]}
                      styles={getSelectStyle(Boolean(error))}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.label}
                      className={classNames({ error: Boolean(error) })}
                    />
                    {error && <small className="text-danger form-text">{error.message}</small>}
                  </div>
                )}
              />

              <Controller
                name="component"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <div className="col-6 form-group">
                    <label for="component">Component</label>
                    <Select
                      {...field}
                      openMenuOnFocus
                      inputId="component"
                      options={COMPONENTS}
                      styles={getSelectStyle(Boolean(error))}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.label}
                      className={classNames({ error: Boolean(error) })}
                    />
                    {error && <small className="text-danger form-text">{error.message}</small>}
                  </div>
                )}
              />

              <Controller
                name="subComponent"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <div className="col-6 form-group">
                    <label for="subComponent">Sub component</label>
                    <Select
                      {...field}
                      openMenuOnFocus
                      inputId="subComponent"
                      options={subComponentOptions}
                      styles={getSelectStyle(Boolean(error))}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.label}
                      className={classNames({ error: Boolean(error) })}
                    />
                    {error && <small className="text-danger form-text">{error.message}</small>}
                  </div>
                )}
              />

              <Controller
                name="material"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <div className="col-6 form-group">
                    <label for="material">Material</label>
                    <Select
                      {...field}
                      openMenuOnFocus
                      inputId="material"
                      options={materialOptions}
                      styles={getSelectStyle(Boolean(error))}
                      getOptionValue={(option) => option.label}
                      getOptionLabel={(option) => option.label}
                      className={classNames({ error: Boolean(error) })}
                    />
                    {error && <small className="text-danger form-text">{error.message}</small>}
                  </div>
                )}
              />

              <Controller
                name="description"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <div className="col-6 form-group">
                    <label for="description">Description</label>
                    <Select
                      {...field}
                      openMenuOnFocus
                      inputId="description"
                      options={descriptionOptions}
                      styles={getSelectStyle(Boolean(error))}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.label}
                      className={classNames({ error: Boolean(error) })}
                    />
                    {error && <small className="text-danger form-text">{error.message}</small>}
                  </div>
                )}
              />

              <Controller
                name="type"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <div className="col-6 form-group">
                    <label for="type">Type</label>
                    <Select
                      {...field}
                      openMenuOnFocus
                      inputId="type"
                      options={typeOptions}
                      styles={getSelectStyle(Boolean(error))}
                      getOptionValue={(option) => option.label}
                      getOptionLabel={(option) => option.label}
                      className={classNames({ error: Boolean(error) })}
                    />
                    {error && <small className="text-danger form-text">{error.message}</small>}
                  </div>
                )}
              />

              <Controller
                name="intensity"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <div className="col-6 form-group">
                    <label for="intensity">Intensity</label>
                    <Select
                      {...field}
                      openMenuOnFocus
                      inputId="intensity"
                      options={intensityOptions}
                      styles={getSelectStyle(Boolean(error))}
                      getOptionValue={(option) => option.label}
                      getOptionLabel={(option) => option.label}
                      className={classNames({ error: Boolean(error) })}
                    />
                    {error && <small className="text-danger form-text">{error.message}</small>}
                  </div>
                )}
              />

              <Controller
                name="extent"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <div className="col-6 form-group">
                    <label for="extent">Extent</label>
                    <Select
                      {...field}
                      openMenuOnFocus
                      inputId="extent"
                      options={extentOptions}
                      styles={getSelectStyle(Boolean(error))}
                      getOptionValue={(option) => option.label}
                      getOptionLabel={(option) => option.label}
                      className={classNames({ error: Boolean(error) })}
                    />
                    {error && <small className="text-danger form-text">{error.message}</small>}
                  </div>
                )}
              />

              <div className="col-12">
                <div className="row">
                  <div className="col-6 form-group">
                    <label for="comment">Comment</label>
                    <textarea
                      className="form-control "
                      id="comment"
                      {...register('comment')}></textarea>
                    {errors.comment && (
                      <small className="text-danger form-text">{errors.comment.message}</small>
                    )}
                  </div>

                  <div className="col-6 form-group">
                    <label for="resolution">Resolution</label>
                    <textarea
                      className="form-control "
                      id="resolution"
                      {...register('resolution')}></textarea>
                    {errors.resolution && (
                      <small className="text-danger form-text">{errors.resolution.message}</small>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-12 d-flex justify-content-center">
                <button type="submit" className="btn btn-primary py-2 px-4">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </BaseModal>
  );
};

CreateNewDefectModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};
export default CreateNewDefectModal;
