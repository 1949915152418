import { get, isEmpty, omit } from 'lodash';

export const initialState = {
  listMission: {
    data: [],
    loading: true,
    total_page: 0
  },
  missionDetail: {
    data: {},
    loading: true
  },
  discardImage: {
    data: {},
    loading: true
  },
  missionSummary: {
    data: {},
    loading: true
  },
  missionCostSummary: {
    data: {},
    loading: true
  },
  statusFile: {},
  missionElevation: {
    data: {},
    loading: true
  },
  isUpdateStatus: {},
  isUpdateNENStatusDefect: {},
  listBuildingForSelect: {
    data: {},
    loading: true
  },
  isCreateMission: {
    data: {},
    loading: true
  },
  isRemoveMission: {
    data: {},
    loading: true
  },
  notifyMission: {
    data: {},
    loading: true
  },
  actionHistory: {
    data: {},
    loading: true
  },
  actionHistoryDetails: {
    data: {},
    loading: true
  },
  uploadPFIRectifiedPhotoMetadata: {
    data: {},
    loading: true
  },
  updateCompletedDefect: {
    data: {},
    loading: false
  },
  checkParticularInspection: {
    data: {},
    loading: false
  },
  checkParticularAccessWordV2: {
    data: {},
    loading: false
  },
  defectStatusOptions: {
    data: [],
    loading: false
  },
  remarkOptions: {
    data: [],
    loading: false
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'GET_MISSION_LIST_REQUEST':
      return { ...state, listMission: { ...initialState.listMission } };

    case 'GET_MISSION_LIST_SUCCESS': {
      const { missions, total_page } = action.payload;
      return {
        ...state,
        listMission: { data: isEmpty(missions) ? [] : [...missions], total_page, loading: false }
      };
    }
    case 'GET_MISSION_REQUEST': {
      return { ...state, missionDetail: { ...initialState.missionDetail } };
    }
    case 'GET_MISSION_SUCCESS': {
      return {
        ...state,
        missionDetail: { data: action.payload, loading: false }
      };
    }
    case 'DISCARD_IMAGES_REQUEST': {
      return { ...state, discardImage: { ...initialState.discardImage } };
    }
    case 'DISCARD_IMAGES_SUCCESS': {
      return {
        ...state,
        discardImage: { data: action.payload, loading: false }
      };
    }
    case 'GET_MISSION_SUMMARY_REQUEST': {
      return { ...state, missionSummary: { ...initialState.missionSummary } };
    }
    case 'GET_MISSION_SUMMARY_SUCCESS': {
      return {
        ...state,
        missionSummary: { data: action.payload, loading: false }
      };
    }
    case 'GET_MISSION_COST_SUMMARY_REQUEST': {
      return { ...state, missionCostSummary: { ...initialState.missionCostSummary } };
    }
    case 'GET_MISSION_COST_SUMMARY_SUCCESS': {
      return {
        ...state,
        missionCostSummary: { data: action.payload, loading: false }
      };
    }
    case 'GET_STATUS_FILE_REQUEST': {
      return { ...state, statusFile: { ...initialState.statusFile } };
    }
    case 'GET_STATUS_FILE_SUCCESS': {
      return { ...state, statusFile: action.payload };
    }
    case 'GET_MISSION_ELEVATION_REQUEST': {
      return {
        ...state,
        missionElevation: { ...initialState.missionElevation }
      };
    }
    case 'GET_MISSION_ELEVATION_SUCCESS': {
      return {
        ...state,
        missionElevation: { data: action.payload, loading: false }
      };
    }
    case 'CHECK_PARTICULAR_INSPECTION_REQUEST': {
      return {
        ...state,
        checkParticularInspection: {
          ...initialState.checkParticularInspection,
          loading: true
        }
      };
    }

    case 'CHECK_PARTICULAR_INSPECTION_SUCCESS': {
      return {
        ...state,
        checkParticularInspection: {
          data: action.payload,
          loading: false
        }
      };
    }

    case 'UPDATE_STATUS_DEFECT_REQUEST': {
      return { ...state, isUpdateStatus: { ...initialState.isUpdateStatus } };
    }
    case 'UPDATE_STATUS_DEFECT_SUCCESS': {
      return { ...state, isUpdateStatus: action.payload };
    }

    case 'UPUPDATE_NEN_STATUS_DEFECT_REQUEST': {
      return { ...state, isUpdateNENStatusDefect: { ...initialState.isUpdateNENStatusDefect } };
    }
    case 'UPDATE_NEN_STATUS_DEFECT_SUCCESS': {
      return { ...state, isUpdateNENStatusDefect: action.payload };
    }
    case 'UPDATE_MISSION_ELEVATION': {
      const {
        currentSlickIndex,
        indexDefect,
        status,
        comments,
        pfi_rectified_comment,
        pfi_rectified_image_url,
        defectStatus,
        defectLocation,
        defectArea,
        remark
      } = action.payload;
      let { data } = state.missionElevation;
      data[currentSlickIndex].defects[indexDefect].status = status;
      data[currentSlickIndex].defects[indexDefect].comments = comments;
      data[currentSlickIndex].defects[indexDefect].pfi_rectified_comment = pfi_rectified_comment;
      data[currentSlickIndex].defects[indexDefect].pfi_rectified_image_url =
        pfi_rectified_image_url;
      data[currentSlickIndex].defects[indexDefect].defect_status = defectStatus;
      data[currentSlickIndex].defects[indexDefect].defect_area = defectArea;
      data[currentSlickIndex].defects[indexDefect].defect_location = defectLocation;
      data[currentSlickIndex].defects[indexDefect].remark = remark;
      return { ...state, missionElevation: { data: data, loading: false } };
    }
    case 'GET_BUILDING_SELECT_REQUEST': {
      return {
        ...state,
        listBuildingForSelect: { ...initialState.listBuildingForSelect }
      };
    }
    case 'GET_BUILDING_SELECT_SUCCESS': {
      return {
        ...state,
        listBuildingForSelect: { data: action.payload, loading: false }
      };
    }
    case 'CREATE_NEW_MISSION_REQUEST': {
      return { ...state, isCreateMission: { ...initialState.isCreateMission } };
    }
    case 'CREATE_NEW_MISSION_SUCCESS': {
      return {
        ...state,
        isCreateMission: { data: action.payload, loading: false }
      };
    }
    case 'DELETE_MISSION_REQUEST': {
      return { ...state, isRemoveMission: { ...initialState.isRemoveMission } };
    }
    case 'DELETE_MISSION_SUCCESS': {
      return {
        ...state,
        isRemoveMission: { data: action.payload, loading: false }
      };
    }

    case 'GET_NOTIFY_MISSION_REQUEST': {
      return { ...state, notifyMission: { ...initialState.notifyMission } };
    }
    case 'GET_NOTIFY_MISSION_SUCCESS': {
      return {
        ...state,
        notifyMission: { data: action.payload, loading: false }
      };
    }

    case 'GET_ACTION_HISTORY_REQUEST': {
      return { ...state, actionHistory: { ...initialState.actionHistory } };
    }
    case 'GET_ACTION_HISTORY_SUCCESS': {
      return {
        ...state,
        actionHistory: { data: action.payload.data, loading: false }
      };
    }

    case 'GET_ACTION_HISTORY_DETAILS_REQUEST': {
      return {
        ...state,
        actionHistoryDetails: { ...initialState.actionHistoryDetails }
      };
    }
    case 'GET_ACTION_HISTORY_DETAILS_SUCCESS': {
      return {
        ...state,
        actionHistoryDetails: {
          data: action.payload.data.histories[0],
          loading: false
        }
      };
    }

    case 'SET_ACTION_HISTORY_DETAILS': {
      return {
        ...state,
        actionHistoryDetails: {
          data: action.payload,
          loading: false
        }
      };
    }
    case 'UPDATE_DEFECT_RECTIFICATION': {
      const { elevationIndex, defectIndex, updates } = action.payload;
      let { data } = state.missionElevation;

      const currentDefect = get(data, `${elevationIndex}.defects.${defectIndex}`);
      if (!currentDefect) return state;
      currentDefect.rectification_upload = {
        ...currentDefect.rectification_upload,
        ...omit(updates, 's3_image')
      };
      data[elevationIndex].defects[defectIndex] = currentDefect;
      return { ...state, missionElevation: { data: data, loading: false } };
    }
    case 'GET_UPLOAD_PFI_RECTIFIED_PHOTO_METADATA_REQUEST': {
      return {
        ...state,
        uploadPFIRectifiedPhotoMetadata: {
          loading: true,
          data: {}
        }
      };
    }
    case 'GET_UPLOAD_PFI_RECTIFIED_PHOTO_METADATA_SUCCESS': {
      return {
        ...state,
        uploadPFIRectifiedPhotoMetadata: {
          data: action.payload,
          loading: false
        }
      };
    }
    case 'UPDATE_COMPLETED_DEFECT_REQUEST': {
      return { ...state, updateCompletedDefect: { loading: true } };
    }
    case 'UPDATE_COMPLETED_DEFECT_SUCCESS': {
      return { ...state, updateCompletedDefect: { ...action.payload, loading: false } };
    }
    case 'CHECK_PARTICULAR_ACCESS_WORD_V2_REQUEST': {
      return {
        ...state,
        checkParticularAccessWordV2: {
          ...initialState.checkParticularAccessWordV2,
          loading: true
        }
      };
    }
    case 'CHECK_PARTICULAR_ACCESS_WORD_V2_SUCCESS': {
      return {
        ...state,
        checkParticularAccessWordV2: {
          data: action.payload,
          loading: false
        }
      };
    }

    case 'GET_DEFECT_STATUS_OPTIONS_REQUEST': {
      return {
        ...state,
        defectStatusOptions: {
          ...initialState.defectStatusOptions,
          loading: true
        }
      };
    }

    case 'GET_DEFECT_STATUS_OPTIONS_SUCCESS': {
      return {
        ...state,
        defectStatusOptions: {
          data: action.payload.data,
          loading: false
        }
      };
    }

    case 'GET_REMARK_OPTIONS_REQUEST': {
      return {
        ...state,
        defectStatusOptions: {
          ...initialState.remarkOptions,
          loading: true
        }
      };
    }

    case 'GET_REMARK_OPTIONS_SUCCESS': {
      return {
        ...state,
        remarkOptions: {
          data: action.payload.data,
          loading: false
        }
      };
    }

    default:
      return state;
  }
};
