import { UPDATE_STORE } from './type';

export const appendRequestId = (requestId) => {
  return {
    type: UPDATE_STORE,
    payload: {
      type: 'APPEND_REQUEST_ID',
      requestId: requestId
    }
  };
};

export const removeRequestId = (requestId) => {
  return {
    type: UPDATE_STORE,
    payload: {
      type: 'REMOVE_REQUEST_ID',
      requestId: requestId
    }
  };
};
