import * as THREE from 'three';
import { vec3 } from 'gl-matrix';

function worldToLocal(worldXYZ, cameraXYZ, cameraQuat) {
  // Convert arrays to THREE.Vector3 and THREE.Quaternion
  const cameraPosition = new THREE.Vector3(cameraXYZ[0], cameraXYZ[1], cameraXYZ[2]);
  const quaternion = new THREE.Quaternion(
    cameraQuat[0],
    cameraQuat[1],
    cameraQuat[2],
    cameraQuat[3]
  );

  // Translate world coordinates to the camera's origin
  const translatedPoint = worldXYZ.clone().sub(cameraPosition);

  // Rotate the translated point by the inverse of the camera's quaternion
  const invQuat = quaternion.clone().invert();
  const localXYZ = translatedPoint.applyQuaternion(invQuat);
  return localXYZ;
}

function xyzToEquirectangular(localXYZ, imgWidth, imgHeight) {
  const x = localXYZ.x;
  const y = localXYZ.y;
  const z = localXYZ.z;

  const yaw = Math.atan2(x, z);
  const hyp = Math.sqrt(x * x + z * z);
  const pitch = Math.atan2(y, hyp);

  const textureX = ((yaw + Math.PI) / (2 * Math.PI)) * imgWidth;
  const textureY = ((Math.PI / 2 - pitch) / Math.PI) * imgHeight;
  return [textureX, textureY];
}

export function convertPointBetweenImages(img1Data, img2Data, imgWidth, imgHeight) {
  const img1CameraXYZ = img1Data['xyz'];

  const img2CameraXYZ = img2Data['xyz'];
  const img2CameraQuat = img2Data['quats'];

  // Convert the point from world coordinates to local coordinates in image 2
  const worldPointXYZ = new THREE.Vector3(img1CameraXYZ[0], img1CameraXYZ[1], img1CameraXYZ[2]);
  const localXYZInImg2 = worldToLocal(worldPointXYZ, img2CameraXYZ, img2CameraQuat);

  // Convert to texture coordinates in image 2
  const [textureX, textureY] = xyzToEquirectangular(localXYZInImg2, imgWidth, imgHeight);

  return { textureX: textureX, textureY: textureY };
}

export const RAD_TO_DEG = 180 / Math.PI;

export const clamp = (x, min, max) => Math.max(Math.min(x, max), min);

export const circulate = (val, min, max) => {
  const size = Math.abs(max - min);

  if (val < min) {
    const offset = (min - val) % size;
    val = max - offset;
  } else if (val > max) {
    const offset = (val - max) % size;
    val = min + offset;
  }

  return val;
};

export function quatToEuler(quaternion) {
  const x = quaternion[0];
  const y = quaternion[1];
  const z = quaternion[2];
  const w = quaternion[3];
  const x2 = x * x;
  const y2 = y * y;
  const z2 = z * z;
  const w2 = w * w;

  const unit = x2 + y2 + z2 + w2;
  const test = x * w - y * z;

  let pitch, yaw;

  if (test > 0.499995 * unit) {
    // singularity at the north pole
    pitch = Math.PI / 2;
    yaw = 2 * Math.atan2(y, x);
  } else if (test < -0.499995 * unit) {
    // singularity at the south pole
    pitch = -Math.PI / 2;
    yaw = -2 * Math.atan2(y, x);
  } else {
    const view = vec3.fromValues(0, 0, 1);
    const up = vec3.fromValues(0, 1, 0);

    vec3.transformQuat(view, view, quaternion);
    vec3.transformQuat(up, up, quaternion);

    const viewXZ = Math.sqrt(view[0] * view[0] + view[2] * view[2]);

    pitch = Math.atan2(-view[1], viewXZ);
    yaw = Math.atan2(view[0], view[2]);
  }

  return {
    pitch: clamp(pitch * RAD_TO_DEG, -90, 90),
    yaw: circulate(yaw * RAD_TO_DEG, 0, 360)
  };
}
